import { logger } from "configs/logger";
import * as React from "react";
import { IconProps } from "./types";
const log = logger("InstagramI");

const InstagramI: React.FunctionComponent<IconProps> = (props) => {
  const color = props.color ? props.color : "#000";

  return (
    <svg
      className={props.className}
      ref={props.forwardRef}
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M11.005 6.61c-3.123 0-5.643 2.404-5.643 5.385 0 2.982 2.52 5.386 5.643 5.386 3.123 0 5.642-2.404 5.642-5.386 0-2.98-2.519-5.386-5.642-5.386zm0 8.887c-2.018 0-3.668-1.57-3.668-3.502 0-1.93 1.645-3.501 3.668-3.501s3.668 1.57 3.668 3.501c0 1.932-1.65 3.502-3.668 3.502zm7.19-9.108c0 .699-.59 1.256-1.317 1.256-.732 0-1.316-.562-1.316-1.256s.59-1.256 1.316-1.256c.727 0 1.316.562 1.316 1.256zm3.736 1.275c-.083-1.683-.486-3.173-1.777-4.401-1.287-1.229-2.849-1.613-4.612-1.697-1.817-.099-7.263-.099-9.08 0-1.758.08-3.32.464-4.61 1.692C.56 4.486.161 5.977.073 7.659c-.104 1.735-.104 6.933 0 8.668.083 1.682.486 3.173 1.777 4.401 1.292 1.228 2.848 1.613 4.611 1.697 1.817.098 7.263.098 9.08 0 1.763-.08 3.325-.464 4.612-1.697 1.286-1.228 1.689-2.719 1.777-4.401.103-1.735.103-6.929 0-8.663zm-2.347 10.524c-.383.918-1.125 1.626-2.092 1.996-1.449.549-4.886.422-6.487.422s-5.043.122-6.487-.422a3.634 3.634 0 01-2.092-1.997c-.575-1.382-.442-4.664-.442-6.192 0-1.528-.128-4.814.442-6.192.383-.919 1.124-1.626 2.092-1.997 1.448-.548 4.886-.422 6.487-.422 1.6 0 5.043-.122 6.487.422a3.634 3.634 0 012.092 1.997c.574 1.383.442 4.664.442 6.192 0 1.528.132 4.814-.442 6.193z"
          fill="#000"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0h22v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default InstagramI;
