import { NormalizedTagType } from "domain/tag";

export const tagData: NormalizedTagType = {
  ["redux"]: {
    name: "redux",
  },
  ["redux-saga"]: {
    name: "Redux Sage",
  },
  ["react-hook"]: {
    name: "React Hook",
  },
  ["react-ref"]: {
    name: "React Ref",
  },
  ["create-react-app"]: {
    name: "CRA",
  },
  ["typescript"]: {
    name: "TypeScript",
  },
  ["styled-components"]: {
    name: "styled-components",
  },
  ["c-plus-plus"]: {
    name: "C++",
  },
  ["linked-list"]: {
    name: "Linked-List",
  },
  ["leetcode"]: {
    name: "LeetCode",
  },
  ["string"]: {
    name: "String",
  },
  ["palindromic"]: {
    name: "Palindromic",
  },
  ["array"]: {
    name: "Array",
  },
  ["kmp"]: {
    name: "KMP",
  },
  ["binary-search"]: {
    name: "Binary Search",
  },
  ["math"]: {
    name: "Math",
  },
  ["reselect"]: {
    name: "Reselect",
  },
  ["java"]: {
    name: "Java",
  },
  ["docker"]: {
    name: "Docker",
  },
  ["aws"]: {
    name: "AWS",
  },
  ["react"]: {
    name: "React",
  },
};
