import { NormalizedCategoryType } from "domain/category";
import AlgorithmI from "./AlgorithmI";
import ProjectI from "./ProjectI";
import ReactI from "./ReactI";
import { IconProps } from "./types";

export declare type MapPropType = keyof NormalizedCategoryType;

export declare type IconMapType = {
  [key in MapPropType]: React.FunctionComponent<IconProps>;
};

/**
 * you need to add at least one blog to display the category. Otherwise, it won't show up
 */
export const iconMap: IconMapType = {
  react: ReactI,
  algorithm: AlgorithmI,
  projects: ProjectI,
};
