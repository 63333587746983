import { NormalizedBlogType } from "domain/blog";
import { categoryData } from "./category";
import { tagData } from "./tag";

export const blogData: NormalizedBlogType = {
  ["horizontal-list-with-swipe"]: {
    title: "Horizontal List With Swipe | React",
    category: categoryData["reactjs"],
    componentName: "horizontal-list-with-swipe",
    createdAt: new Date(2020, 11, 17), // js Date month start from 0 (not 1)
    updatedAt: new Date(2021, 7, 3),
    description:
      "This is a step-by-step tutorial to create a responsive horizontal list with a swipe (esp mobile) based on React. The component displays the left/right arrow for large screen sizes and the swipe feature for smaller screen sizes. No framework/dependency is necessary to implement this component. you can achieve this with React library only.",
    path: "horizontal-list-with-swipe",
    tags: [tagData["typescript"], tagData["react-hook"], tagData["react-ref"]],
  },
  ["add-two-numbers-linked-list"]: {
    title: "Add Two Numbers - Linked List | Algorithm",
    category: categoryData["algorithm"],
    componentName: "add-two-numbers-linked-list",
    createdAt: new Date(2021, 0, 16), // js Date month start from 0 (not 1)
    updatedAt: new Date(2021, 0, 16),
    description:
      "This is my solution for a Leetcode question (level: easy). I provide a step-by-step tutorial including video and code. I chose C++ as the programming language, and my solution produces 24ms which beats 93.90% and its memory usage is 71.2 MB which beats 84.82%. Its time complexity is O(n) and space complexity is O(n).",
    path: "add-two-numbers-linked-list",
    tags: [tagData["c-plus-plus"], tagData["linked-list"], tagData["leetcode"]],
  },
  ["longest-substring-without-repeating-character"]: {
    title: "Longest Substring Without Repeating Character - String | Algorithm",
    category: categoryData["algorithm"],
    componentName: "longest-substring-without-repeating-character",
    createdAt: new Date(2021, 0, 17), // js Date month start from 0 (not 1)
    updatedAt: new Date(2021, 0, 17),
    description:
      "This is my solution for a Leetcode question (level: easy). I provide a step-by-step tutorial including video and code. I chose C++ as the programming language, and my solution produces 16ms which beats 74.09% and its memory usage is 8.3 MB which beats 76.10%. Its time complexity is O(n) and space complexity is O(n).",
    path: "longest-substring-without-repeating-character",
    tags: [tagData["c-plus-plus"], tagData["string"], tagData["leetcode"]],
  },
  ["longest-palindromic-substring"]: {
    title: "Longest Palindromic Substring - String | Algorithm",
    category: categoryData["algorithm"],
    componentName: "longest-palindromic-substring",
    createdAt: new Date(2021, 0, 19), // js Date month start from 0 (not 1)
    updatedAt: new Date(2021, 0, 19),
    description:
      "This is my solution for the Leetcode question (level: medium). I provide a step-by-step tutorial including video and code. I chose C++ as the programming language, and my solution produces 28 ms and its memory usage is 7.5 MB. Its time complexity is O(n^2) and space complexity is O(1).",
    path: "longest-palindromic-substring",
    tags: [
      tagData["c-plus-plus"],
      tagData["string"],
      tagData["leetcode"],
      tagData["palindromic"],
    ],
  },
  ["valid-parentheses"]: {
    title: "Valid Parentheses - String | Algorithm",
    category: categoryData["algorithm"],
    componentName: "valid-parentheses",
    createdAt: new Date(2021, 0, 20), // js Date month start from 0 (not 1)
    updatedAt: new Date(2021, 0, 20),
    description:
      "This is my solution for the Leetcode question (level: easy). I provide a step-by-step tutorial including video and code. I chose C++ as the programming language, and my solution produces 0 ms and its memory usage is 6.8 MB. Its time complexity is O(n) and space complexity is O(n).",
    path: "valid-parentheses",
    tags: [tagData["c-plus-plus"], tagData["string"], tagData["leetcode"]],
  },
  ["merge-two-sorted-lists"]: {
    title: "Merge Two Sorted Lists - Linked List | Algorithm",
    category: categoryData["algorithm"],
    componentName: "merge-two-sorted-lists",
    createdAt: new Date(2021, 0, 21), // js Date month start from 0 (not 1)
    updatedAt: new Date(2021, 0, 21),
    description:
      "This is my solution for the Leetcode question (level: easy). I provide a step-by-step tutorial including video and code. I chose C++ as the programming language, and my solution produces 8 ms and its memory usage is 14.7 MB. Its time complexity is O(n) where n is the max length of the 1st node list and the 2nd node list, and space complexity is O(1).",
    path: "merge-two-sorted-lists",
    tags: [tagData["c-plus-plus"], tagData["linked-list"], tagData["leetcode"]],
  },
  ["horizontal-list-with-multiple-items"]: {
    title: "Horizontal List With Multiple Items | react",
    category: categoryData["reactjs"],
    componentName: "horizontal-list-with-multiple-items",
    createdAt: new Date(2021, 0, 21), // js Date month start from 0 (not 1)
    updatedAt: new Date(2021, 0, 21),
    description:
      "This is a step-by-step tutorial to create a responsive horizontal list with multiple items based on create-react-app (CRA). Also, there are several main dependencies like styled-components and TypeScript. You can display a list horizontally and click arrow icons to scroll left/right or swipe on mobile to browse items. The source code is available on my GitHub repository, so if you are interested in this, please visit my GitHub page.",
    path: "horizontal-list-with-multiple-items",
    tags: [
      tagData["styled-components"],
      tagData["typescript"],
      tagData["create-react-app"],
      tagData["react-hook"],
    ],
  },
  ["remove-element"]: {
    title: "Remove Element - Array | Algorithm",
    category: categoryData["algorithm"],
    componentName: "remove-element",
    createdAt: new Date(2021, 0, 22), // js Date month start from 0 (not 1)
    updatedAt: new Date(2021, 0, 22),
    description:
      "This is my solution for the Leetcode question (level: easy). I provide a step-by-step tutorial including video and code. I chose C++ as the programming language, and my solution produces 0 ms and its memory usage is 8.6 MB. Its time complexity is O(n) and space complexity is O(1).",
    path: "remove-element",
    tags: [tagData["c-plus-plus"], tagData["array"], tagData["leetcode"]],
  },
  ["implement-strstr"]: {
    title: "Implement strStr() - String | Algorithm",
    category: categoryData["algorithm"],
    componentName: "implement-strstr",
    createdAt: new Date(2021, 0, 25), // js Date month start from 0 (not 1)
    updatedAt: new Date(2021, 0, 25),
    description:
      "This is my solution for Implement strStr(), a Leetcode question (level: easy). I provide a step-by-step tutorial including video and code. I chose C++ as the programming language, and my solution produces 0 ms and its memory usage is 6.7 MB. Its time complexity is O(n+m) and space complexity is O(n) with KMP algorithm for pattern searching.",
    path: "implement-strstr",
    tags: [
      tagData["c-plus-plus"],
      tagData["string"],
      tagData["leetcode"],
      tagData["kmp"],
    ],
  },
  ["search-insert-position"]: {
    title: "Search Insert Position - Array | Algorithm",
    category: categoryData["algorithm"],
    componentName: "search-insert-position",
    createdAt: new Date(2021, 0, 26), // js Date month start from 0 (not 1)
    updatedAt: new Date(2021, 0, 26),
    description:
      "This is my solution for Search Insert Position, a Leetcode question (level: easy). I provide a step-by-step tutorial including video and code. I chose C++ as the programming language, and my solution produces 8 ms and its memory usage is 9.9 MB. Its time complexity is O(log n) and space complexity is O(log n).",
    path: "search-insert-position",
    tags: [
      tagData["c-plus-plus"],
      tagData["array"],
      tagData["leetcode"],
      tagData["binary-search"],
    ],
  },
  ["intersection-of-two-linked-lists"]: {
    title: "Intersection of Two Linked Lists - Linked List | Algorithm",
    category: categoryData["algorithm"],
    componentName: "intersection-of-two-linked-lists",
    createdAt: new Date(2021, 1, 5), // js Date month start from 0 (not 1)
    updatedAt: new Date(2021, 1, 5),
    description:
      "This is my solution for Intersection of Two Linked Lists, a Leetcode question (level: easy). I provide a step-by-step tutorial including video and code. I chose C++ as the programming language, and my solution produces 76 ms and its memory usage is 14.5 MB. Its time complexity is O(m+n) where m is the length of one linked list and n is the length of the other linked list, and space complexity is O(1) with the binary search algorithm.",
    path: "intersection-of-two-linked-lists",
    tags: [tagData["c-plus-plus"], tagData["linked-list"], tagData["leetcode"]],
  },
  ["two-sum-ii-input-array-is-sorted"]: {
    title: "Two Sum II - Input Array Is Sorted - Array | Algorithm",
    category: categoryData["algorithm"],
    componentName: "two-sum-ii-input-array-is-sorted",
    createdAt: new Date(2021, 1, 5), // js Date month start from 0 (not 1)
    updatedAt: new Date(2021, 1, 5),
    description:
      "This is my solution for Two Sum II - Input Array Is Sorted, a Leetcode question (level: easy). I provide a step-by-step tutorial including video and code. I chose C++ as the programming language, and my solution produces 8 ms and its memory usage is 9.5 MB. Its time complexity is O(n) where m is the length of one linked list and n is the length of the other linked list, and space complexity is O(1).",
    path: "two-sum-ii-input-array-is-sorted",
    tags: [tagData["c-plus-plus"], tagData["array"], tagData["leetcode"]],
  },
  ["excel-sheet-column-title"]: {
    title: "Excel Sheet Column Title - Math | Algorithm",
    category: categoryData["algorithm"],
    componentName: "excel-sheet-column-title",
    createdAt: new Date(2021, 1, 5), // js Date month start from 0 (not 1)
    updatedAt: new Date(2021, 1, 5),
    description:
      "This is my solution for Excel Sheet Column Title, a Leetcode question (level: easy). I provide a step-by-step tutorial including video and code. I chose C++ as the programming language, and my solution produces 0 ms and its memory usage is 5.7 MB. Its time complexity is O(n*log n) where m is the length of one linked list and n is the length of the other linked list, and space complexity is O(n).",
    path: "excel-sheet-column-title",
    tags: [tagData["c-plus-plus"], tagData["leetcode"], tagData["math"]],
  },
  ["reactjs-redux-saga-reselector-architecture"]: {
    title: "React + Redux-Saga + Reselect (TypeScript) Architecture | React",
    category: categoryData["reactjs"],
    componentName: "reactjs-redux-saga-reselector-architecture",
    createdAt: new Date(2021, 1, 6), // js Date month start from 0 (not 1)
    updatedAt: new Date(2021, 1, 16),
    description:
      "This is the high-level architecture that I use for React projects. As main dependencies,  I use Redux, Redux-Saga, and Reselect to accommodate any complicated project. I explain the basic flow of how React with those dependencies work together from the user event to the update of the UI component. Also, the demo GitHub repository is available to start using for your next project.",
    path: "reactjs-redux-saga-reselector-architecture",
    tags: [tagData["redux-saga"], tagData["typescript"], tagData["reselect"]],
  },
  ["mastering-pagination-with-reactjs"]: {
    title: "Mastering Pagination With React | React",
    category: categoryData["reactjs"],
    componentName: "mastering-pagination-with-reactjs",
    createdAt: new Date(2021, 1, 16), // js Date month start from 0 (not 1)
    updatedAt: new Date(2021, 1, 16),
    description:
      "This is how I implemented the pagination feature using React. No external library is required to implement this pagination except for the main dependencies such as TypeScript, redux-saga, and reselect. I explain the logic and implementation in detail step by step using my demo project.",
    path: "mastering-pagination-with-reactjs",
    tags: [tagData["redux-saga"], tagData["typescript"], tagData["reselect"]],
  },
  ["mastering-typeahead-search-with-reactjs"]: {
    title: "Mastering Typeahead Search With React | React",
    category: categoryData["reactjs"],
    componentName: "mastering-typeahead-search-with-reactjs",
    createdAt: new Date(2021, 1, 16), // js Date month start from 0 (not 1)
    updatedAt: new Date(2021, 1, 16),
    description:
      "This is how I implemented the keyword typeahead searching feature using React. No external library is required to implement this feature except for the main dependencies are React, TypeScript, redux-saga, and reselect. I explain the logic and implementation in detail step by step using my demo project.",
    path: "mastering-typeahead-search-with-reactjs",
    tags: [tagData["redux-saga"], tagData["typescript"], tagData["reselect"]],
  },
  ["mastering-sort-and-filter-with-reactjs"]: {
    title: "Mastering Sort and Filter With React | React",
    category: categoryData["reactjs"],
    componentName: "mastering-sort-and-filter-with-reactjs",
    createdAt: new Date(2021, 1, 17), // js Date month start from 0 (not 1)
    updatedAt: new Date(2021, 1, 17),
    description:
      "This is how I implemented the sorting and filtering feature using React. No external library is required to implement this feature except for the main dependencies are React, TypeScript, redux-saga, and reselect. I explain the logic and implementation in detail step by step using my demo project.",
    path: "mastering-sort-and-filter-with-reactjs",
    tags: [tagData["redux-saga"], tagData["typescript"], tagData["reselect"]],
  },
  ["mastering-api-request-with-reactjs"]: {
    title: "Mastering API Request With React | React",
    category: categoryData["reactjs"],
    componentName: "mastering-api-request-with-reactjs",
    createdAt: new Date(2021, 1, 17), // js Date month start from 0 (not 1)
    updatedAt: new Date(2021, 1, 17),
    description:
      "This tutorial explains how to implement API requesting with React, Redux, Redux-Saga, and Reselect. I will use a visual diagram to describe the overall steps to accomplish this API fetching feature. Then, I'm going to show my code in the implementation section. This tutorial describes the flow step by step with my demo application.",
    path: "mastering-api-request-with-reactjs",
    tags: [tagData["redux-saga"], tagData["typescript"], tagData["reselect"]],
  },
  ["mastering-cache-with-reactjs"]: {
    title: "Mastering Cache With React | React",
    category: categoryData["reactjs"],
    componentName: "mastering-cache-with-reactjs",
    createdAt: new Date(2021, 1, 17), // js Date month start from 0 (not 1)
    updatedAt: new Date(2021, 1, 17),
    description:
      "This tutorial explains how to implement the caching feature with React, Redux, Redux-Saga, and Reselect. I will use a visual diagram to describe the overall steps to accomplish this caching feature. No external library is required for this implementation. Then, I'm going to show my code in the implementation section. This tutorial describes the flow step by step with my demo application.",
    path: "mastering-cache-with-reactjs",
    tags: [tagData["redux-saga"], tagData["typescript"], tagData["reselect"]],
  },
  ["mastering-practical-features-with-reactjs"]: {
    title: "Mastering Practical Features With React | React",
    category: categoryData["reactjs"],
    componentName: "mastering-practical-features-with-reactjs",
    createdAt: new Date(2021, 1, 17), // js Date month start from 0 (not 1)
    updatedAt: new Date(2021, 1, 17),
    description:
      "This tutorial explains how to implement the practical features with React such as pagination, sorting, filtering, searching, API fetching, and caching. The main dependencies are TypeScript, Redux, Redux-Saga, Reselect, and Normalizr. I demonstrate those features using my demo app.",
    path: "mastering-practical-features-with-reactjs",
    tags: [tagData["redux-saga"], tagData["typescript"], tagData["reselect"]],
  },
  ["auto-complete-search-input-bar"]: {
    title: "Auto-Complete Search Input Bar From Scratch",
    category: categoryData["reactjs"],
    componentName: "auto-complete-search-input-bar",
    createdAt: new Date(2021, 7, 6), // js Date month start from 0 (not 1)
    updatedAt: new Date(2021, 7, 6),
    description:
      "This is a step-by-step tutorial to create an auto-complete search input from scratch without any framework esp Material-UI. The component auto-suggests and filters out any unmatched items with a dropdown list when users enter a keyword.",
    path: "auto-complete-search-input-bar",
    tags: [tagData["typescript"], tagData["react-hook"], tagData["react-ref"]],
  },
  ["useful-custom-hooks-you-should-know"]: {
    title: "Useful Custom Hooks You Should Know",
    category: categoryData["reactjs"],
    componentName: "useful-custom-hooks-you-should-know",
    createdAt: new Date(2021, 7, 8), // js Date month start from 0 (not 1)
    updatedAt: new Date(2021, 7, 8),
    description:
      "This blog introduces useful custom hooks with React and TypeScript, and it briefly goes over the basics of hooks and their rules. We can create our custom hooks beside built-in hooks such as useEffect. Custom hooks allow us to extract duplicated application logic from each component and increase the reusability. The custom hooks are useFetch, usePrevious, useResponsive, and useScroll.",
    path: "useful-custom-hooks-you-should-know",
    tags: [tagData["typescript"], tagData["react-hook"], tagData["react-ref"]],
  },
  ["e-commerce-and-admin-web-app"]: {
    title: "E-Commerce and Admin Web App",
    category: categoryData["projects"],
    componentName: "e-commerce-and-admin-web-app",
    createdAt: new Date(2021, 8, 9), // js Date month start from 0 (not 1)
    updatedAt: new Date(2021, 8, 9),
    description:
      "This blog introduces my e-commerce web app. the features include online payment transactions and online order system, dashboard, product, category, account, order, review, customer management. Also, I integrated with Stripe, a payment gateway system, and Canada Post API. the tech stack used to develop this app is React.js, Redux, Java, Spring Boot, Docker, and AWS. the introductory video that summarizes the features is available at my Youtube channel.",
    path: "e-commerce-and-admin-web-app",
    tags: [
      tagData["react"],
      tagData["java"],
      tagData["docker"],
      tagData["aws"],
    ],
  },
};
