import * as React from 'react';
import './style.scss';
import { logger } from 'configs/logger';
import { useLocation } from 'react-router';
import { animated, useTransition, useSpring, useChain, config } from 'react-spring'
import { usePrevious } from 'hooks/previous';
const log = logger("CategoryBCLayer");

export declare type CategoryBCLayerPropsType = {
  categoryPaths: string[],
  blogPaths: string[],
}


const CategoryBCLayer: React.FunctionComponent<CategoryBCLayerPropsType> = (props) => {

  const location = useLocation()
  const previousPathName = usePrevious({
    value: location.pathname
  })

  const [springStyle, setSpringStyle, stopSpringStyle] = useSpring(() => { opacity: 1 })

  /**
   * tricky part here
   *
   * you CAN'T use default and simpley 'useSpring' (like below), otherwise, the animation is applied twice every time the path changes because of 'useTransition' on RoutePage Compoment. for example:
   *  1. update (1st) for fading out the previous page component
   *  2. update (2nd) for fading in the next page component
   *
   *  => this ends up update this component twice. (since this component use 'useLocation" also)
   *
   * code)
   *  const spring = useSpring(condition ? { ... } : { ... })
   *
   * - this is because we want to apply the animation only when location.pathname changes and specifically moving to Home or Category.
   * - so, use below format:
   *
   * code)
   *  const [springStyle, setSpringStyle, stopSpringStyle] = useSpring(() => { opacity: 1})
   *
   *  * you need to put function as parameter in the 'useSpring'
   *
   *
   **/

  /**
   * use this as background for all pages (not only category)
   *
   * H: Home,
   * C: Category,
   * B: Blog,
   * A: About Me,
   * P: Privacy Policy
   *
   *  - if previous page path does not exist, it returns 'undefined', so use '!previousPathName'
   *
   **/

  React.useEffect(() => {

    log(`previous: ${previousPathName}`)
    log(`current: ${location.pathname}`)
    log(`category paths: `)
    log(props.categoryPaths)
    log(`includes?: ${props.categoryPaths.includes(location.pathname)}`)

    if (previousPathName !== location.pathname) {

      const otherPaths = ["/about-me", "/privacy-policy", "/terms-and-conditions", ...props.blogPaths]
      // H -> C
      if ((previousPathName == "/" || !previousPathName) && props.categoryPaths.includes(location.pathname)) {
        log("from home to category")
        setSpringStyle({
          to: async (next: any) => {
            await next({
              transform: `translate(-50%, 100%)`,
            })
            await next({
              transform: `translate(-50%, 0%)`,
            })
          }
        })
      }


      // C -> H 
      else if ((props.categoryPaths.includes(previousPathName) || !previousPathName) && location.pathname == "/") {
        log("from cate to home")
        setSpringStyle({
          to: async (next: any) => {
            await next({
              transform: `translate(0%, 0%)`,
            })
            await next({
              transform: `translate(0%, 99%)`,
            })
          }
        })
      }

      // C -> B
      else if ((props.categoryPaths.includes(previousPathName)) && props.blogPaths.includes(location.pathname)) {
        setSpringStyle({
          to: async (next: any) => {
            await next({
              transform: `translate(0%, 0%)`,
            })
            await next({
              transform: `translate(99.50%, 0%)`,
            })
          }
        })
      }

      // B -> C 
      else if ((props.blogPaths.includes(previousPathName)) && props.categoryPaths.includes(location.pathname)) {
        setSpringStyle({
          to: async (next: any) => {
            await next({
              transform: `translate(100%, 0%)`,
            })
            await next({
              transform: `translate(-50%, 0%)`,
            })
          }
        })
      }

      // H -> A, B, P, or those are the initial page to be visited
      else if ((previousPathName == "/" || !previousPathName) && otherPaths.includes(location.pathname)) {
        setSpringStyle({
          to: async (next: any) => {
            await next({
              transform: `translate(0%, 0%)`,
            })
            await next({
              transform: `translate(99.50%, 0%)`,
            })
          }
        })
      }

      // A, B, or P -> H 
      else if (otherPaths.includes(previousPathName) && location.pathname == "/") {
        log("about > home")
        setSpringStyle({
          to: async (next: any) => {
            await next({
              transform: `translate(0%, 0%)`,
            })
            await next({
              transform: `translate(0%, 99%)`,
            })
          }
        })
      }

    }
  }, [location.pathname])

  return (
    <animated.div
      className="category-bc-layer"
      style={springStyle}
    >
    </animated.div>
  );
}

export default CategoryBCLayer;

