import { logger } from "configs/logger";
import * as React from "react";
import { IconProps } from "./types";
const log = logger("ProjectI");

const ProjectI: React.FunctionComponent<IconProps> = (props) => {
  const color = props.color ? props.color : "#000";

  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      ref={props.forwardRef}
    >
      <path
        d="M37.1 14H13.9C12.305 14 11.0145 15.2937 11.0145 16.875L11 34.125C11 35.7063 12.305 37 13.9 37H37.1C38.695 37 40 35.7063 40 34.125V16.875C40 15.2937 38.695 14 37.1 14ZM29.85 34.125H13.9V28.375H29.85V34.125ZM29.85 26.9375H13.9V21.1875H29.85V26.9375ZM37.1 34.125H31.3V21.1875H37.1V34.125Z"
        fill="black"
      />
    </svg>
  );
};

export default ProjectI;
