import Content from 'components/pages/Content';
import { logger } from 'configs/logger';
import { store } from 'configs/storeConfig';
import { CssGlobalContext, CssGlobalContextDefaultState } from 'contexts/cssGlobal';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { useLocation } from "react-router";
import { BrowserRouter as Router } from 'react-router-dom';
import smoothscroll from 'smoothscroll-polyfill';
import 'ui/css/common.scss';
const log = logger(__filename)

/**
 * wierd bug with css-loader:
 *
 * ERROR in ./src/UI/Base/Css/Fonts.scss (./node_modules/css-loader/dist/cjs.js!./node_modules/postcss-loader/src??ref--7-2!./node_modules/sass-loader/dist/cjs.js!./src/UI/Base/Css/Fonts.scss)
 * Module build failed (from ./node_modules/postcss-loader/src/index.js):
 * Error: Invalid mapping: {"generated":{"line":1,"column":95},"source":"src/UI/Base/Css/Fonts.scss","original":{"column":null},"name":null}
 *
 * when use import statement with url to import google font at this Fonts.scss, it produce above errors
 *  - i don't know why.
 *  - workaround: moving the import statement to Common.scss solve this error.
 **/
/**
 * font from google fonts
 **/
//import "ui/Base/Css/Fonts.scss";
/**
 * scroll polyfill
 *   - make sure to call 'polyfill' function
 **/
smoothscroll.polyfill();

// import css for debug (only development)
//if (NODE_ENV === 'development') require('ui/Base/Css/Debug.scss');

/**
 * scroll to top when new page visit
 * https://reacttraining.com/react-router/web/guides/scroll-restoration
 **/
const ScrollToTop: React.FunctionComponent<{}> = (props) => {

  const { pathname } = useLocation()

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

const Index = (props: any) => {

  return (
    <div className="app-wrapper">
      <CssGlobalContext.Provider value={CssGlobalContextDefaultState}>
        <Provider store={store}>
          <Router>
            <ScrollToTop />
            <Content />
          </Router>
        </Provider>
      </CssGlobalContext.Provider>
    </div>
  );
};

ReactDOM.render(
  <Index />
  , document.getElementById('root')
)
