import { logger } from 'configs/logger';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HashLink as Link } from 'react-router-hash-link';
import { rightNavMenuActions, searchModalActions } from 'reducers/slices/ui';
import { mSelector } from 'sideEffects/selector';
import GitHubI from '../icons/GitHubI';
import InstagramI from '../icons/InstagramI';
import MenuI from '../icons/MenuI';
import SearchI from '../icons/SearchI';
import "./style.scss";
const log = logger("Header")
const Logo = require("src/assets/logo.svg");


const Header: React.FunctionComponent<{}> = (props) => {

  /**
   * search modal feature
   **/
  const curSearchModalStatus: boolean = useSelector(mSelector.makeSearchModalSelector())

  // dispatch callback - open search modal    
  const dispatch = useDispatch()
  const openSearchModal = React.useCallback(
    () => dispatch(searchModalActions.open()),
    [dispatch]
  )
  // menu icon click event handler
  const handleSearchModalIconClickEvent: React.EventHandler<React.MouseEvent<SVGElement>> = React.useCallback((e) => {
    openSearchModal()
  }, [])

  /**
   * right nav feature
   **/

  // dispatch callback - open right nav menu bar    
  const openRightNavMenu = React.useCallback(
    () => dispatch(rightNavMenuActions.open()),
    [dispatch]
  )

  // menu icon click event handler
  const handleMenuIconClickEvent: React.EventHandler<React.MouseEvent<SVGElement>> = React.useCallback((e) => {
    openRightNavMenu()
  }, [])

  return (
    <header className="header-wrapper">
      <Link
        className="link"
        to={`/`}
        smooth
      >
        <img className="header-logo" src={Logo} alt="stsdev-logo" />
      </Link>
      <div className="header-right-wrapper">
        <a href="https://www.instagram.com/stsdev1551/" target="_blank"> 
          <InstagramI className="icon icon-size"></InstagramI>
        </a>
        <a href="https://github.com/stsiwo" target="_blank"> 
          <GitHubI className="icon icon-size"></GitHubI>
        </a>
        <SearchI className="icon rotate-90-transition" onClick={handleSearchModalIconClickEvent}/>
        <MenuI className="icon x-180-rotate-transition" onClick={handleMenuIconClickEvent} />
      </div>
    </header>
  )
}

export default Header
