import { logger } from 'configs/logger';
import * as React from 'react';
import { IconProps } from './types';
const log = logger("Menu")

const MenuI: React.FunctionComponent<IconProps> = (props) => {

  const color = props.color ? props.color : "#000";

  return (
    <svg 
      width="24" 
      height="24" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      onClick={props.onClick}
    >
      <path 
        fillRule="evenodd" 
        clipRule="evenodd" 
        d="M2 8V6h20v2H2zm0 3v2h20v-2H2zm0 5v2h20v-2H2z" 
        fill={color}/>
    </svg>
  )
}

export default MenuI
