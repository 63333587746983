import { logger } from 'configs/logger';
import { BlogType } from 'domain/blog';
import { TagType } from 'domain/tag';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HashLink as Link } from 'react-router-hash-link';
import { animated, useChain, useTransition } from 'react-spring';
import { searchKeywordActions } from 'reducers/slices/app';
import { searchModalActions } from 'reducers/slices/ui';
import { mSelector } from 'sideEffects/selector';
import { getLatestDate, toDateString } from 'src/utils';
import { downNoLeaveTransitionComponent, downTransitionComponent, popupTransitionComponent } from 'ui/animations';
import CloseI from '../icons/CloseI';
import { iconMap, MapPropType } from '../icons/map';
import "./style.scss";
const log = logger("SearchModal")


const SearchModal: React.FunctionComponent<{}> = (props) => {

  const dispatch = useDispatch()


  /**
   * search input stuff
   **/
  const curSearchKeyword: string = useSelector(mSelector.makeSearchKeywordSelector())
  const updateSearchKeyword = React.useCallback(
    (searchKeyword: string) => dispatch(searchKeywordActions.update(searchKeyword)),
    [dispatch]
  )
  const handleSearchInputChangeEvent: React.EventHandler<React.ChangeEvent<HTMLInputElement>> = (e) => {
    updateSearchKeyword(e.currentTarget.value)
  }

  /**
   * search result stuff
   **/
  const curSearchResult: BlogType[] = useSelector(mSelector.makeBlogDataBySearchSelector())

  // event handler when a user click one of the result item 
  const handleSearchResultItemClickEvent: React.EventHandler<React.MouseEvent<HTMLAnchorElement>> = React.useCallback((e) => {
    closeSearchModal()
  }, [])

  /**
   * search modal feature
   **/
  const curSearchModalStatus: boolean = useSelector(mSelector.makeSearchModalSelector())

  // dispatch callback - open search modal    
  const closeSearchModal = React.useCallback(
    () => dispatch(searchModalActions.close()),
    [dispatch]
  )
  // menu icon click event handler
  const handleSearchModalCloseIconClickEvent: React.EventHandler<React.MouseEvent<SVGElement>> = React.useCallback((e) => {
    closeSearchModal()
  }, [])

  /**
   * animation
   **/

  // search modal wrapper
  const searchModalWrapperRef = React.useRef()
  const searchModalWrapperTransition = useTransition(curSearchModalStatus ? [0] : [], {
    ref: searchModalWrapperRef,
    ...downTransitionComponent,
  })

  // search input element
  const searchInputRef = React.useRef()
  const searchInputTransition = useTransition(curSearchModalStatus ? [0] : [], {
    ref: searchInputRef,
    ...popupTransitionComponent,
  })

  // search content elements
  const searchContentRef = React.useRef()
  const searchContentTransition = useTransition(curSearchModalStatus ? curSearchResult : [], {
    /** need to set keys otherwise, previous result still exists forever **/
    keys: (item: BlogType) => item.path,
    ref: searchContentRef,
    trail: 1200 / curSearchResult.length,
    ...downNoLeaveTransitionComponent,
  })

  useChain(curSearchModalStatus ? [searchModalWrapperRef, searchInputRef, searchContentRef] : [searchContentRef, searchInputRef, searchModalWrapperRef])

  return searchModalWrapperTransition((style: any, item: any) => (
    <animated.div className="search-modal-wrapper" style={style}>
      <CloseI className="icon search-modal-icon rotate-90-transition" onClick={handleSearchModalCloseIconClickEvent} />
      <div className="search-modal-box">
        {searchInputTransition((inputStyle: any, inputItem: any) => (
          <animated.div className="search-input-wrapper" style={inputStyle}>
            <input
              type="text"
              className="input search-modal-input"
              placeholder="enter keyword, category, or tag name here..."
              value={curSearchKeyword}
              onChange={handleSearchInputChangeEvent}
            />
          </animated.div>
        ))}
        <hr />
        <div className="search-content-wrapper">
          {searchContentTransition((contentStyle: any, contentItem: BlogType) => {
            const CategoryIcon = iconMap[contentItem.category.icon as MapPropType]
            return (
              <Link
                className="link "
                to={`/${contentItem.category.path}/${contentItem.path}`}
                smooth
                onClick={handleSearchResultItemClickEvent}
              >
                <animated.div className="horizontal-blog-item-box" style={contentStyle}>
                  <CategoryIcon className="icon horizontal-blog-icon" />
                  <div className="horizontal-blog-detail">
                    <div className="horizontal-blog-title">{contentItem.title}</div>
                    <div className="horizontal-blog-inner-box">
                      <div className="horizontal-blog-tags">
                        {contentItem.tags.map((tag: TagType) => (
                          <div className="horizontal-blog-tag" key={tag.name}>{tag.name}</div>
                        ))}
                      </div>
                      <div className="horizontal-blog-date">{toDateString(getLatestDate(contentItem.createdAt, contentItem.updatedAt))}</div>
                    </div>
                  </div>
                </animated.div>
              </Link>
            )
          })}
        </div>
      </div>
    </animated.div>
  ))
}

export default SearchModal

