import { logger } from 'configs/logger';
import * as React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { animated, useTransition, useSpring, useChain, config } from 'react-spring'
import "./style.scss"
import { useSelector, useDispatch } from 'react-redux';
import { mSelector } from 'sideEffects/selector';
import CloseI from '../icons/CloseI';
import { rightNavMenuActions } from 'reducers/slices/ui';
import { upTransitionComponent } from 'ui/animations';
import { CategoryType } from 'domain/category';
const log = logger("Nav")

type LinkItem = {
  path: string,
  label: string,
}

const Nav: React.FunctionComponent<{}> = (props) => {


  // categories
  const curCategories: CategoryType[] = useSelector(mSelector.makeCategoryDataSelector())

  // get state from memorized selector
  const curNavStatus: boolean = useSelector(mSelector.makeRightNavMenuSelector())

  // dispatch callback - close right nav menu bar    
  const dispatch = useDispatch()
  const closeRightNavMenu = React.useCallback(
    () => dispatch(rightNavMenuActions.close()),
    [dispatch]
  )

  // menu close icon click event handler
  const handleMenuCloseIconClickEvent: React.EventHandler<React.MouseEvent<SVGElement>> = React.useCallback((e) => {
    closeRightNavMenu()
  }, [])

  // menu close icon click event handler
  const handleNavItemClickEvent: React.EventHandler<React.MouseEvent<HTMLAnchorElement>> = React.useCallback((e) => {
    closeRightNavMenu()
  }, [])

  /**
   * animation for toggling nav menu
   *
   * - use useSpring instead of useTransition for boolean state for nav menu
   *
   **/

  //const pageTransLayer1Ref = React.useRef();
  //const pageTransLayer1styles = useSpring(
  //  curNavStatus ? {
  //    ref: pageTransLayer1Ref,
  //    //opacity: 1,
  //    transform: "translate(-100%, 0%)",
  //  } : {
  //    ref: pageTransLayer1Ref,
  //    //opacity: 0,
  //    transform: "translate(100%, 0%)",
  //  }
  //)

  const pageTransLayersRef = React.useRef();
  const pageTransLayersInitialStyleList = [
    {
      backgroundColor: "#ACDDFF",
    },
    {
      backgroundColor: "#7DCAFF",
    },
    {
      backgroundColor: "#0099FF",
    }
  ]
  const pageTransLayersTransitions = useTransition(curNavStatus ? pageTransLayersInitialStyleList : [], {
    ref: pageTransLayersRef,
    /* this makes us allow to do sequence for each link element in this array item */
    trail: 500 / 3,
    //reverse: curNavStatus ? false : true,
    from: {
      transform: "translate(100%, 0%) ",
    },
    enter: {
      transform: "translate(0%, 0%)",
    },
    leave: {
      transform: "translate(100%, 0%)",
    },
  })

  const navBoxRef = React.useRef();
  const styles = useSpring(
    curNavStatus ? {
      ref: navBoxRef,
      //opacity: 1,
      transform: "translate(0%, 0%)",
    } : {
        ref: navBoxRef,
        //opacity: 0,
        transform: "translate(100%, 0%)",
      }
  )
  const linkRef = React.useRef()
  const linkTransitions = useTransition(curNavStatus ? curCategories : [], {
    ref: linkRef,
    /* this makes us allow to do sequence for each link element in this array item */
    trail: 400 / curCategories.length,
    ...upTransitionComponent,
  })

  useChain(curNavStatus ? [pageTransLayersRef, navBoxRef, linkRef] : [linkRef, navBoxRef, pageTransLayersRef])

  return (
    <React.Fragment>
      {pageTransLayersTransitions((style: any, item: any) => (
        <animated.div
          style={{
            ...style,
            ...item,
          }}
          className="page-transition-layer-1">
          <CloseI onClick={handleMenuCloseIconClickEvent} className="icon nav-close-icon rotate-90-transition" />
          <nav className="nav-box">
            {linkTransitions((innerStyle: any, item: CategoryType) => (
              <animated.div style={innerStyle}>
                <Link
                  className="link nav-link"
                  to={`/${item.path}`}
                  onClick={handleNavItemClickEvent}
                  smooth
                >
                  {item.name}
                </Link>
              </animated.div>
            ))}
          </nav>
        </animated.div>
      ))}
    </React.Fragment>
  )
}

export default Nav






