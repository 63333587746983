import { logger } from 'configs/logger';
import { takeEvery } from 'redux-saga/effects';
import { leftNavMenuWorkerWorker } from 'sideEffects/workers/leftNavMenuWorker';
import { toggleLeftNavMenuActionTypeName } from 'reducers/slices/ui';
const log = logger("watchers")
/**
 *  watcher
 **/
export function* leftNavMenuWatcher() {
  log("start watching any request leftNavMenu action")
  yield takeEvery(
    toggleLeftNavMenuActionTypeName,
    leftNavMenuWorkerWorker,
  )
}

