import { logger } from 'configs/logger';
import * as React from 'react';
import { IconProps } from './types';
const log = logger("AlgorithmI")

const AlgorithmI: React.FunctionComponent<IconProps> = (props) => {

  const color = props.color ? props.color : "#000";

  return (
    <svg viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" className={props.className} ref={props.forwardRef}>
      <path d="M23.172 19.636l3.282-3.382m-3.291 7.947l3.282 3.381m3.47-10.545l3.283 3.382M20.028 24.2l-3.282 3.381m13.225 3.782l3.283 3.382m-6.417-3.382l-3.282 3.382m2.947-17.98a2.783 2.783 0 01-.052-3.926 2.761 2.761 0 013.914-.053 2.783 2.783 0 01.052 3.927 2.761 2.761 0 01-3.914.052zm-6.912 7.122a2.783 2.783 0 01-.052-3.927 2.761 2.761 0 013.914-.052 2.783 2.783 0 01.052 3.926 2.762 2.762 0 01-3.914.053zm6.903 3.182a2.783 2.783 0 00-.052 3.927 2.761 2.761 0 003.914.052 2.783 2.783 0 00.052-3.926 2.761 2.761 0 00-3.914-.053zm6.753-7.163a2.783 2.783 0 00-.052 3.926 2.761 2.761 0 003.914.053 2.783 2.783 0 00.052-3.927 2.761 2.761 0 00-3.914-.052zM16.698 27.07a2.783 2.783 0 01.053 3.927 2.761 2.761 0 01-3.914.052 2.783 2.783 0 01-.052-3.926 2.761 2.761 0 013.913-.053zm16.603 7.164a2.783 2.783 0 00-.052 3.926 2.761 2.761 0 003.914.053 2.783 2.783 0 00.052-3.927 2.761 2.761 0 00-3.914-.052zm-9.794 0a2.783 2.783 0 01.052 3.926 2.761 2.761 0 01-3.914.053 2.783 2.783 0 01-.052-3.927 2.761 2.761 0 013.914-.052z" stroke="#000" strokeWidth="2" />
    </svg>
  )
}

export default AlgorithmI

