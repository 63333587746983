import { NormalizedCategoryType } from "domain/category";

export const categoryData: NormalizedCategoryType = {
  ["reactjs"]: {
    icon: "react",
    name: "React",
    path: "reactjs",
  },
  ["algorithm"]: {
    icon: "algorithm",
    name: "Algorithm",
    path: "algorithm",
  },
  ["projects"]: {
    icon: "projects",
    name: "Projects",
    path: "projects",
  },
};
