export const popupTransitionComponent = {
  from: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
  },
  leave: {
    opacity: 0,
  },
}

export const downTransitionComponent = {
  from: {
    opacity: 0,
    transform: "translateY(-50px)",
  },
  enter: {
    opacity: 1,
    transform: "translateY(0px)",
  },
  leave: {
    opacity: 0,
    transform: "translateY(-50px)",
  },
}

export const rightTransitionComponent = {
  from: {
    opacity: 0,
    transform: "translateX(-50px)",
  },
  enter: {
    opacity: 1,
    transform: "translateX(0px)",
  },
  leave: {
    opacity: 0,
    transform: "translateX(-50px)",
  },
}

export const upTransitionComponent = {
  from: {
    opacity: 0,
    transform: "translateY(50px)",
  },
  enter: {
    opacity: 1,
    transform: "translateY(0px)",
  },
  leave: {
    opacity: 0,
    transform: "translateY(50px)",
  },
}

export const downNoLeaveTransitionComponent = {
  from: {
    opacity: 0,
    transform: "translateY(-50px)",
  },
  enter: {
    opacity: 1,
    transform: "translateY(0px)",
  },
}
