import { logger } from 'configs/logger';
import * as React from 'react';
import { IconProps } from './types';
const log = logger("Menu")

const CloseI: React.FunctionComponent<IconProps> = (props) => {

  const color = props.color ? props.color : "#000";

  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={props.onClick} className={props.className}>
      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" fill={color}/>
    </svg>
  )
}

export default CloseI

