import { logger } from 'configs/logger';
import * as React from 'react';
import { IconProps } from './types';
const log = logger("GitHubI")

const GitHubI: React.FunctionComponent<IconProps> = (props) => {

  const color = props.color ? props.color : "#000";

  return (
    <svg className={props.className} ref={props.forwardRef} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.358 18.628c0 .094-.102.169-.23.169-.147.014-.249-.061-.249-.169 0-.094.102-.169.231-.169.133-.014.248.061.248.17zm-1.379-.21c-.031.093.058.2.19.229.116.047.25 0 .276-.094.026-.094-.058-.201-.191-.244-.115-.032-.244.014-.275.108zm1.96-.08c-.128.032-.217.121-.204.23.014.093.13.154.262.121.129-.033.218-.122.204-.216-.013-.089-.133-.15-.261-.136zM10.86.374C4.705.375 0 5.311 0 11.813c0 5.198 3.096 9.646 7.518 11.212.568.108.767-.262.767-.567 0-.29-.013-1.894-.013-2.878 0 0-3.105.703-3.757-1.397 0 0-.505-1.364-1.233-1.716 0 0-1.015-.736.071-.722 0 0 1.105.094 1.712 1.21.972 1.81 2.6 1.289 3.234.98.102-.75.39-1.27.71-1.58-2.48-.29-4.982-.67-4.982-5.18 0-1.29.338-1.936 1.047-2.76-.115-.306-.492-1.562.116-3.184.927-.304 3.06 1.266 3.06 1.266a9.897 9.897 0 012.786-.399c.944 0 1.898.136 2.785.399 0 0 2.133-1.575 3.06-1.266.608 1.627.231 2.878.116 3.183.71.83 1.144 1.477 1.144 2.761 0 4.523-2.612 4.884-5.092 5.18.408.37.754 1.073.754 2.175 0 1.58-.013 3.534-.013 3.918 0 .305.204.675.767.568C18.993 21.459 22 17.01 22 11.813 22 5.31 17.01.374 10.858.374zM4.31 16.542c-.057.047-.044.155.031.244.071.075.173.108.231.047.058-.047.044-.155-.031-.244-.071-.075-.173-.108-.23-.047zm-.479-.38c-.03.061.014.136.102.183.071.047.16.033.191-.032.031-.061-.013-.136-.102-.183-.089-.028-.16-.014-.19.033zm1.437 1.67c-.07.06-.044.2.058.29.102.108.23.122.288.047.058-.061.031-.202-.057-.29-.098-.109-.231-.123-.289-.048zm-.505-.69c-.071.047-.071.169 0 .277.07.108.19.154.248.108.071-.061.071-.183 0-.291-.062-.108-.177-.155-.248-.094z" fill="#000" />
    </svg>
  )
}

export default GitHubI


