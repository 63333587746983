import { PayloadAction, Action } from "@reduxjs/toolkit";
import { logger } from "configs/logger";
import { put } from "redux-saga/effects";
import { leftNavMenuActions } from "reducers/slices/ui";
const log = logger("workers:leftNavMenuWorker")

/**
 * a worker (generator)    
 *  
 **/
export function* leftNavMenuWorkerWorker(action: PayloadAction<{}>) {

  yield put(
    leftNavMenuActions.toggle()
  )
}

