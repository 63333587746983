import Header from 'components/common/Header';
import Nav from 'components/common/Nav';
import SearchModal from 'components/common/SearchModal';
import { logger } from 'configs/logger';
import * as React from 'react';
import RoutePage from '../RoutePage';
const log = logger("Content")

const Content: React.FunctionComponent<{}> = (props) => {

  return (
    <div className="content-wrapper">
      <Header /> 
      <Nav />
      <SearchModal />
      <RoutePage />
    </div>
  )
}

export default Content
