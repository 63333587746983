import loadable from '@loadable/component';
import CategoryBCLayer from 'components/common/CategoryBCLayer';
import Loading from 'components/common/Loading';
import { logger } from 'configs/logger';
import { BlogType } from 'domain/blog';
import { CategoryType } from 'domain/category';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { animated, useChain, useTransition } from 'react-spring';
import { mSelector } from 'sideEffects/selector';
import "./style.scss";
//import Home from '../Home';
//import PrivacyPolicy from '../PrivacyPolicy';
//import AboutMe from '../AboutMe';
//import Category from '../Category';
//import Blog from '../Blogs';
const log = logger("Content")

/**
 * lazy loading component
 *
 *  with 'Loadable Components packages: https://loadable-components.com/docs/getting-started/
 **/
const Blog = loadable(() => import("../Blogs"))
const Category = loadable(() => import("../Category"))
const Home = loadable(() => import("../Home"))
const PrivacyPolicy = loadable(() => import("../PrivacyPolicy"))
const TermsAndConditions = loadable(() => import("../TermsAndConditions"))
const AboutMe = loadable(() => import("../AboutMe"))


declare type RouteItem = {
  path: string,
  Component: React.FunctionComponent<{ style: any }>
}

const RoutePage: React.FunctionComponent<{}> = (props) => {

  /**
   * category list
   **/
  const curCategories = useSelector(mSelector.makeCategoryDataSelector())
  /**
   * blog list
   **/
  const curBlogs = useSelector(mSelector.makeBlogDataSelector())

  /**
   * route
   **/
  const location = useLocation()

  /**
   * animation (page transition)
   **/

  /**
   * useTransition with sequential animations on same element
   *
   * - from/enter/leave: [ cssObj1, cssObj2, ...] => does not work. (i think only 'useSpring')
   *
   * - how to create multiple animations based on the 'useTransition' on the same element?
   * - I want to add another animation after the 1st animation on pageTransLayer element
   *
   * - 2020/12/08: disable page transition with react router
   *
   *   - I want to apply different animations based on the next path. react router does not have such a feature.
   *
   **/
  //const pageTransLayerStyles = useSpring(
  //  {
  //    ref: pageTransLayerRef,
  //    to: {
  //      transform: "translate(-100%, 0%)",
  //    },
  //    from: {
  //      transform: "translate(100%, 0%)",
  //    }
  //  } 
  //)


  const transitionRef = React.useRef()
  const transition = useTransition(location, {
    ref: transitionRef,
    keys: (item: any) => item.pathname.split('/')[1],
    config: {
      duration: 500,
    },
    from: {
      opacity: 0
    },
    enter: {
      opacity: 1
    },
    leave: {
      opacity: 0
    },
  })

  useChain([transitionRef], [0])

  /**
   * 2nd argument: array to specify when to start each animation
   *
   *  - useful when you want to start the 2nd animation during the 1st animation (like in the middle of 1st animation)
   *
   *  - in this example,
   *    - start 2nd animation in the middle of 1st animation.
   *    - the 1st animation takes 1sec and start 2nd at 0.5sec
   *    - this is because we want to move to next page while layer cover the whole page so that any user can't see the page change.
   **/
  //useChain([pageTransLayerRef, pageTransRef], [0, 0.5])

  /**
   * prepare categories & blogs path
   *
   **/
  const categoryPaths = curCategories.map((category: CategoryType) => `/${category.path}`)
  const blogPaths = curBlogs.map((blog: BlogType) => `/${blog.category.path}/${blog.path}`)

  if (curCategories.length === 0 || curBlogs.length === 0) return (
    <Loading />
  )

  return (
    <React.Fragment>
      <React.Suspense fallback={<div>Loading...</div>}>
        <Switch location={location}>
          {/**
          * 
          * multiple paths to the same component
          *
          * - array approach: does not work.
          * - regex approach: WORKS!!
          *
          * - ref: https://stackoverflow.com/questions/40541994/multiple-path-names-for-a-same-component-in-react-router
          *
          **/}
          <Route
            //exact 
            //path={`/(|${categoryPaths.join("|")}|${blogPaths.join("|")})`} 
            path={`/`}
            render={(props: any) => <CategoryBCLayer categoryPaths={categoryPaths} blogPaths={blogPaths} />}
          />
        </Switch>

        {/**<Transition
        native
        items={location}
        keys={location.pathname.split('/')[1]}
        from={{ opacity: 0 }}
        enter={{ opacity: 1 }}
        leave={{ opacity: 0 }}
      >
        {(loc: any, state: any) => (style: any) => (
          <animated.div style={style} className="page-box">
            <Switch location={state === 'update' ? location : loc}>
              <Route path="/" exact component={Home} />
              <Route path="/privacy-policy" exact component={PrivacyPolicy} />
              <Route path="/about-me" exact component={AboutMe} />

              {curCategories.map((category: CategoryType) => (
                <Route
                  path={`/${category.path}`}
                  exact
                  key={category.path}
                  render={(props: any) => (
                    <Category
                      {...props}
                      category={category}
                    />
                  )}
                />
              ))}

              {curBlogs.map((blog: BlogType) => (
                <Route
                  path={`/${blog.category.path}/${blog.path}`}
                  exact
                  key={blog.path}
                  render={(props: any) => (
                    <Blog
                      {...props}
                      blog={blog}
                    />
                  )}
                />
              ))}
            </Switch>
          </animated.div>
        )}
      </Transition>**/}
        {transition((style: any, item: any) => {
          return (
            <animated.div style={style} className="page-box">
              <Switch location={item}>
                <Route path="/" exact component={Home} />
                <Route path="/privacy-policy" exact component={PrivacyPolicy} />
                <Route path="/terms-and-conditions" exact component={TermsAndConditions} />
                <Route path="/about-me" exact component={AboutMe} />

                {curCategories.map((category: CategoryType) => (
                  <Route
                    path={`/${category.path}`}
                    exact
                    key={category.path}
                    render={(props: any) => (
                      <Category
                        {...props}
                        category={category}
                      />
                    )}
                  />
                ))}

                {curBlogs.map((blog: BlogType) => (
                  <Route
                    path={`/${blog.category.path}/${blog.path}`}
                    exact
                    key={blog.path}
                    render={(props: any) => (
                      <Blog
                        {...props}
                        blog={blog}
                      />
                    )}
                  />
                ))}
              </Switch>
            </animated.div>
          )
        })
        }
      </React.Suspense>
    </React.Fragment>
  )
}

export default RoutePage


